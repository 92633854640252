import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import hamburguer
import logo from "../../../assets/logo.svg";
import watson from "../../../assets/withWatson.svg";

import hamburguer from "../../../assets/hamburguer.svg";
import RenderImg from "../RenderImg/RenderImg";
import Circle from "../Circle/Circle";
import Menu from "./Menu/Menu";
import "./Header.scss";
import { navigate } from "hookrouter";
import { useDispatch, useSelector } from "react-redux";
import "./Header.scss";
import {
  // getWidth,
  // setWidth,
  setSetInterval,
  // selectIsLoggedIn,
  refresh,
  selectUser,
  setUser,
} from "./headerSlice";
import Connection from "../../../utils/Connection";
import JwtDecode from "jwt-decode";
import useCheckToken from "../../../utils/useCheckToken";
import { setMessage } from "../../Chat/chatSlice";
import moment from "moment";

const Header = ({ props }) => {
  const dispatch = useDispatch();
  // const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  // const interval = useSelector((state) => state.header.interval);
  // const [firstName, setFirstName] = useState("");
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      // dispatch(setWidth(width));
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
  };

  const { width } = useWindowDimensions();

  const [showMenu, setShowMenu] = useState(false);
  
  const checkToken = useCheckToken();
  const setCheckingToken = useCallback(
    (params) => {
      const interval = setInterval(checkToken, 180000);
      dispatch(setSetInterval(interval));
    },
    [dispatch, checkToken]
  );

  useEffect(() => {
    const t = sessionStorage.getItem("t");
    const restrictedPages = ["/chat", "/home"];
    const path = window.location.pathname;
    if (!t && restrictedPages.includes(path)) navigate("/");
    else if (!restrictedPages.includes(path)) {
      checkToken();
    } else if (t) {
      const tokenUser = JwtDecode(t).identity;
      if (!user.firstname) dispatch(setUser(tokenUser));

      checkToken();
      setCheckingToken();
    }
    // Check for browser refresh - if it happened then destroy old WatsonAssistant session and create a new one
    async function resetWASession() {
      let conn = new Connection(t);
      // resetwasession returns a 
      let resetResponse = await conn.create("/user/resetwasession/");
      if (resetResponse.status.code === 200) {
        dispatch(refresh(resetResponse.data.token));
        const tokenUser = JwtDecode(resetResponse.data.token).identity;
        dispatch(setUser(tokenUser));
        sessionStorage.setItem("wa_session", tokenUser['assistant_session']);
        dispatch(
          setMessage({
            type: "response",
            message: resetResponse.data.message,
            date: moment().format("LT, MMM DD, yyyy"),
          })
        );
    
      }
    }
    if (user == null || user["assistant_session"] === "") {
      const wa_session = sessionStorage.getItem("wa_session");
      if (wa_session != null && wa_session !== "") {
        resetWASession();
      }
    }    
  }, [checkToken, dispatch, user.firstname, user, setCheckingToken]);


  return (
    <>
      {showMenu && <Menu show={showMenu} setShow={setShowMenu} />}
      <Container fluid className="header">
        <Row className="justify-content-center">
          {width > 450 ? (
            <>
              <RenderImg
                className="header-logo"
                style={{ display: "block", marginTop: "20px" }}
                src={logo}
                alt="logo"
              />
              <RenderImg
                className="header-logo"
                style={{ display: "block", marginTop: "20px" }}
                src={watson}
                alt="watson"
              />
              <Col></Col>
              {/* {width < 450 ? (
            <Col>
              <RenderImg
                className="box-center"
                style={{ display: "block" }}
                src={logo}
                alt="logo"
              />
            </Col>
          ) : (
            ""
          )} */}
              <Col></Col>
              <Col xs="4">
                {/* <Row className="justify-content-end align-items-center">
                  <Col xs="auto">
                    <Circle color={t ? "#E4B378" : "#C4C4C4"} />
                    <RenderImg
                      onClick={() => setShowMenu(!showMenu)}
                      src={hamburguer}
                      alt="hamburguer"
                    />
                  </Col>
                </Row> */}
              </Col>
            </>
          ) : (
            <>
              {/* <Col xs="auto"> */}
              <RenderImg
                className="box-center"
                style={{ marginLeft: "0px", marginTop: "20px" }}
                src={logo}
                alt="logo"
              />
              <RenderImg
                className="header-logo"
                style={{ marginRight: "30%", marginTop: "20px" }}
                src={watson}
                alt="watson"
              />
              {/* </Col> */}
              {/* <Col xs="auto"> */}

              {/* </Col> */}
            </>
          )}
        </Row>
        <div className="header-hamburger">
          <Row
            className="justify-content-end align-items-center"
            style={{ marginTop: "20px" }}
          >
            <Col xs="auto" className="header-cols">
              <Circle
                color={sessionStorage.getItem("t") ? "#E4B378" : "#C4C4C4"}
              />
            </Col>
            <Col xs="auto" className="header-cols">
              {user?.firstname && width > 450 && (
                <span className="header-username">
                  Hello {user?.firstname}!
                </span>
              )}
              <RenderImg
                onClick={() => setShowMenu(!showMenu)}
                src={hamburguer}
                alt="hamburguer"
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Header;
