import React, { useState } from "react";
import SendButton from "../../modules/SendButton/SendButton";

const SendComponent = () => {
  const [buttonColor, setButtonColor] = useState("#CACACA");
  return (
    <button
      required
      type="submit"
      alt="Send Button"
      className="send-button"
      onMouseEnter={() => setButtonColor("#38B5E6")}
      onMouseLeave={() => setButtonColor("#CACACA")}
    >
      <SendButton color={buttonColor} />
    </button>
  );
};

export default SendComponent;
