import React from "react";
import Connection from "./Connection";
import { navigate } from "hookrouter";
export const stringToJsx = (string = "") => {
  const newString = (
    <>
      {string.split("\n").map((piece, i, arr) => (
        <React.Fragment key={i}>
          {piece}
          {i !== arr.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
  const jsx = newString;
  return jsx;
};

export const startSession = async () => {
  const t = sessionStorage.getItem("t");
  const conn = new Connection(t);
  const response = await conn.create("/user/chatbot/start/");

  if (response.status.code !== 200){
    navigate("/error");
  } else {
    if (response.data.user && "assistant_session" in response.data.user) {
      // Store Watson Assistant session_id in NodeJS session to determine if refresh happens later
      sessionStorage.setItem("wa_session", response.data.user["assistant_session"]);
    }
    return response;
  }
};
