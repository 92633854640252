import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from '../features/counter/counterSlice';
import allReducers from "./combineReducers";
const { headerSlice, chatSlice } = allReducers;
export default configureStore({
  reducer: {
    chat: chatSlice,
    header: headerSlice,
  },
});
