import React from "react";
// import logo from "./logo.svg";
// import { Counter } from "./features/counter/Counter";
import "./App.scss";
import routes from "./routes";
import NotFoundPage from "./features/NotFoundPage/NotFoundPage";
import { useRoutes } from "hookrouter";
import Header from "./features/modules/Header/Header";

function App() {
  const routeResult = useRoutes(routes);
  return (
    <main className="main">
      <Header />
      {routeResult || <NotFoundPage />}
    </main>
  );
}

export default App;
