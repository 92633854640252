import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
//import bgImg from "../../assets/bgImg.svg";
// import withWatson from "../../assets/withWatson.svg";
//import RenderImg from "../modules/RenderImg/RenderImg";
//import CenteredDiv from "../modules/CenteredDiv/CenteredDiv";
// import { setUser } from "../modules/Header/headerSlice";

// Change to mimic Chat instead of a unique Home page
//import "./Home.scss";
import "../Chat/Chat.scss"
import Question from "../Chat/Question/Question";
import Response from "../Chat/Response/Response";
import SidePanel from "../modules/SidePanel/SidePanel";

// import { navigate } from "hookrouter";
// import "./Login.scss";
// import sendBtn from "../../assets/send-btn.svg";
// import { ReactComponent as Logo } from "../../assets/send-btn.svg";
import Connection from "../../utils/Connection";
import { useDispatch } from "react-redux";
import { setMessage } from "../Chat/chatSlice";
import { navigate } from "hookrouter";
import moment from "moment";
import { startSession } from "../../utils/chatFunctions";
// import SendButton from "../modules/SendButton/SendButton";
import SendComponent from "../Chat/SendComponent/SendComponent";
// import { logOut } from "../modules/Header/headerSlice";
import useCheckToken from "../../utils/useCheckToken";
const Home = ({ props }) => {
  const dispatch = useDispatch();
  //const messages = useSelector((state) => state.chat.messages);
  const messages = [{"type": "response", "message": " ", "date": moment().format("LT, MMM DD, yyyy"), "url": "None"}]
  const [question, setQuestion] = useState("");
  const [waiting] = useState(false);
  const checkToken = useCheckToken();

  const sendData = async (e) => {
    const t = sessionStorage.getItem("t");
    const conn = new Connection(t);
    const response = await conn.create("/user/chatbot/send/", {
      message: question,
    });

    dispatch(
      setMessage({
        type: "question",
        message: question,
        date: moment().format("LT, MMM DD, yyyy"),
        url: response.data.url,
      })
    );
    if (response.status.code !== 200) checkToken();
    dispatch(
      setMessage({
        type: "response",
        message:
          response.status.code === 200
            ? response.data.response.map((responses) => responses.text).join("")
            : "Can you repeat the question?",
        date: moment().format("LT, MMM DD, yyyy"),
        url: response.data.url,
      })
    );
    navigate("/chat", true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    sendData();
  };
  useEffect(() => {
    // window.scrollTo(0, document.body.scrollHeight);
    var objDiv = document.getElementById("chat-id");
    objDiv.scrollTop = objDiv.scrollHeight;
    // startSession();
  }, []);


  useEffect(() => {
    async function startMyWASession() {
      let waInitialResponse = await startSession();
      dispatch(
        setMessage({
          type: "response",
          message:
          waInitialResponse ?
            waInitialResponse.status.code === 200 && waInitialResponse.data &&
            waInitialResponse.data.message && waInitialResponse.data.message.result &&
            waInitialResponse.data.message.result[0]
              ? waInitialResponse.data.message.result[0].text
              : ""
            : "",
          date: moment().format("LT, MMM DD, yyyy"),
        })
      );
      if (waInitialResponse && waInitialResponse.status.code === 200) {
        navigate("/chat", true);
      }
    }
    startMyWASession();

    return () => {};
  }, [dispatch]);

  const handleLink = (e) => {
    e.preventDefault();

    var alink = e.target;

    if (alink.tagName.toLowerCase() === "a") {
      var newQuestion = alink.getAttribute("href");
      if (newQuestion.startsWith("https://")) {
        window.open(newQuestion, "_blank");
      } else {
        handleSubmit(e, newQuestion);
      }      
    }
  };

  return (
    <div className="chat">
      <div style={{ height: "100%", display: "flex" }}>
        <SidePanel />
        <div className=" arrow_box">
          <Container
            className="chat-container"
            fluid
            style={{ height: "100%", overflow: "scroll", paddingBottom: "2%" }}
            id="chat-id"
          >
            {/* {stringToJsx(" this is the \n end")} */}
            {messages.map((message, i) =>
              message.type === "question" ? (
                <Question key={i} text={message.message} date={message.date} />
              ) : (
                <Response
                  // url={url}
                  url={message.url}
                  key={i}
                  text={message.message}
                  date={message.date}
                  handleStop={handleLink}
                />
              )
            )}
            {waiting && (
              <Response
                text={<div className="three-dots loading"></div>}
                date={moment().format("LT, MMM DD, yyyy")}
                isJsx
                handleStop={handleLink}
              />
            )}
          </Container>
        </div>
        <div className="chat-question-wrapper">
          <form onSubmit={handleSubmit}>
            <div className="chat-question-wrapper">
              <Row className="justify-content-end align-items-center chat-question-row">
                <Col>
                  <input
                    value={question}
                    name="question"
                    className="login-input chat-question-input question-input"
                    required
                    autoFocus
                    placeholder="Begin typing here..."
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </Col>
                <Col xs="auto">
                  <SendComponent />
                </Col>
              </Row>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
