import { createSlice } from "@reduxjs/toolkit";

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    messages: [],
  },
  reducers: {
    setMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    emptyMessages: (state, action) => {
      state.messages = state.messages.slice(0, 0);
    },
  },
});

export const { setMessage, emptyMessages } = chatSlice.actions;

export default chatSlice.reducer;
