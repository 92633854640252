import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Chat.scss";
// import RenderImg from "../modules/RenderImg/RenderImg";
// import favicon from "../../assets/favicon.svg";
// import sendBtn from "../../assets/send-btn.svg";
// import { messages } from "./chatSlice";
import Question from "./Question/Question";
import Response from "./Response/Response";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "./chatSlice";
import SidePanel from "../modules/SidePanel/SidePanel";
// import { getWidth } from "../modules/Header/headerSlice";

import Connection from "../../utils/Connection";
import moment from "moment";
// import { startSession } from "../../utils/chatFunctions";
import SendComponent from "./SendComponent/SendComponent";

import useCheckToken from "../../utils/useCheckToken";
// import { stringToJsx } from "../../utils/chatFunctions";
// import CenteredDiv from "../modules/CenteredDiv/CenteredDiv";
const Chat = ({ props }) => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.chat.messages);
  // const width = useSelector((state) => state.header.width);
  const [question, setQuestion] = useState("");
  const [waiting, setWaiting] = useState(false);
  const checkToken = useCheckToken();

  const sendData = async (linkq = "") => {
    // e.preventDefault();
    // /chatbot/send
    const t = sessionStorage.getItem("t");
    const conn = new Connection(t);
    var sendQuestion = question;

    if (linkq !== "") {
      sendQuestion = linkq;
    }

    const response = await conn.create("/user/chatbot/send/", {
      message: sendQuestion,
    });

    window.scrollTo(0, document.body.scrollHeight);
    if (response.status.code !== 200) checkToken();
    dispatch(
      setMessage({
        type: "response",
        message:
          response.status.code === 200
            ? response.data.response.map((responses) => responses.text).join("")
            : "Can you repeat the question?",
        date: moment().format("LT, MMM DD, yyyy"),
        url: response.data.url,
      })
    );

    setWaiting(false);
  };
  const handleSubmit = (e, linkq = "") => {
    e.preventDefault();
    setWaiting(true);
    var sendQuestion = question;
    if (linkq !== "") {
      sendQuestion = linkq;
    }

    dispatch(
      setMessage({
        type: "question",
        message: decodeURIComponent(sendQuestion),
        date: moment().format("LT, MMM DD, yyyy"),
      })
    );
    sendData(decodeURIComponent(sendQuestion));
    setQuestion("");

    //scroll to the bottom of the page
  };
  useEffect(() => {
    // window.scrollTo(0, document.body.scrollHeight);
    var objDiv = document.getElementById("chat-id");
    objDiv.scrollTop = objDiv.scrollHeight;
    // startSession();
  }, [messages]);

  const handleLink = (e) => {
    e.preventDefault();

    var alink = e.target;

    if (alink.tagName.toLowerCase() === "a") {
      // Open any href that starts with https in a new window otherwise send it in as a question
      var newQuestion = alink.getAttribute("href");
      if (newQuestion.startsWith("https://")) {
        window.open(newQuestion, "_blank");
      } else {
        handleSubmit(e, newQuestion);
      }      
    }
  };

  return (
    <div className="chat">
      <div style={{ height: "100%", display: "flex" }}>
        <SidePanel />
        <div className=" arrow_box">
          <Container
            className="chat-container"
            fluid
            style={{ height: "95%", overflow: "scroll", paddingBottom: "2%" }}
            id="chat-id"
          >
            {/* {stringToJsx(" this is the \n end")} */}
            {messages.map((message, i) =>
              message.type === "question" ? (
                <Question key={i} text={message.message} date={message.date} />
              ) : (
                <Response
                  // url={url}
                  url={message.url}
                  key={i}
                  text={message.message}
                  date={message.date}
                  handleStop={handleLink}
                />
              )
            )}
            {waiting && (
              <Response
                text={<div className="three-dots loading"></div>}
                date={moment().format("LT, MMM DD, yyyy")}
                isJsx
                handleStop={handleLink}
              />
            )}
          </Container>
        </div>
        <div className="chat-question-wrapper">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
            }}
          >
            <Row className="justify-content-end align-items-center chat-question-row">
              <Col>
                <input
                  className="login-input chat-question-input question-input"
                  placeholder="Begin typing here..."
                  required
                  autoFocus
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </Col>
              <Col xs="auto">
                <SendComponent />
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chat;
