import { createSlice } from "@reduxjs/toolkit";
import { navigate } from "hookrouter";

export const headerSlice = createSlice({
  name: "header",
  initialState: {
    showMenu: false,
    isLoggedIn: false,
    t: "",
    interval: null,
    user: {
      firstname: "",
      lastname: "",
      company: "",
      username: "",
      assistant_session: "",
    },
    error: {
      isError: false,
      message: "",
    },
  },
  reducers: {
    setShowMenu: (state, action) => {
      state.showMenu = !action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSetInterval: (state, action) => {
      state.interval = action.payload;
    },
    setWidth: (state, action) => {
      state.width = action.payload;
    },
    logIn: (state, action) => {
      state.isLoggedIn = true;
      state.t = action.payload;
      sessionStorage.setItem("t", action.payload || "");
      sessionStorage.setItem("wa_session", "");
      navigate("/home", true);
    },
    refresh: (state, action) => {
      state.t = action.payload;
      sessionStorage.setItem("t", action.payload || "");
    },
    logOut: (state, action) => {
      state.isLoggedIn = false;
      state.user = {
        firstname: "",
        lastname: "",
        company: "",
        username: "",
        assistant_session: "",
      };
      navigate("/", false, { ...action.payload });
      if (state.t) {
        clearInterval(state.t);
        state.t = null;
      }
      sessionStorage.clear();
    },
  },
});

export const {
  setShowMenu,
  setWidth,
  logIn,
  refresh,
  logOut,
  setSetInterval,
  setUser,
  setError,
} = headerSlice.actions;
export const selectShowMenu = (state) => state.header.showMenu;
export const getWidth = (state) => state.header.width;
export const selectIsLoggedIn = (state) => state.header.isLoggedIn;
export const selectUser = (state) => state.header.user;
export const selectError = (state) => state.header.error;

export default headerSlice.reducer;
