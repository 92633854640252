import axios from "axios";
export default class Connection {
  /**
   * @param {string} accessToken
   *
   */
  constructor(accessToken) {
    this.accessToken = `Bearer ${accessToken}` || null;
    this.status = { message: "", code: "" };

    const connectionObject = {
      ip: process.env.REACT_APP_BACKEND_HOST
    };

    this.connectionObject = { ...connectionObject };
  }
  /**
   * @param {string} baseUrl
   * @param {*} data
   *
   */
  create(baseUrl, data) {
    return this.request("post", baseUrl, data);
  }
  /**
   * @param {string} baseUrl
   * @param {(string | number)} id
   *
   */
  delete(baseUrl, id) {
    return this.request("delete", `${baseUrl}/${id}`);
  }
  /**
   * @param {string} baseUrl
   * @param {*} data
   *
   */
  update(baseUrl, data) {
    return this.request("patch", baseUrl, data);
  }
  /**
   * @param {string} baseUrl
   */
  get(baseUrl, data) {
    return this.request("get", baseUrl, data);
  }

  setData(data = {}) {
    this.data = data;
  }
  getLastRequestData() {
    return this.data ? this.data : null;
  }
  setStatus(code, message) {
    this.status = { message, code };
  }
  getStatus() {
    return { ...this.status };
  }
  async request(method, url, data) {
    try {

      await axios({
        baseURL: `${this.connectionObject.ip}`,
        method,
        url,
        data,
        headers: {
          "Authorization": this.accessToken,
          "content-type": "application/json",
        },
      })
        .then((response) => {
          this.setData(response["data"]);
          this.setStatus(response.status, response.headers);

        })
        .catch((error) => {
          console.log("ERROR RESPONSE: ", error.response.data);
          console.log("ERROR CODE: ", error.response.status);
          this.setData();
          this.setStatus(error.response.status, error.response.data);
        });

      return { data: this.getLastRequestData(), status: this.getStatus() };
    } catch (error) {
      const status = error.message.split(" ")[
        error.message.split(" ").length - 1
      ];
      this.setStatus(status, error.message);
      return { data: error.response?.data, status, statusText: error.message };
    }
  }

  async uploadWithCancel(url, files, data = null, onUploadProgress, source) {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("files", files.document, files.document.name);
    console.log(data);
    console.log(files);
    await axios({
      baseURL: `${this.connectionObject.ip}`,
      method: "POST",
      url,
      data: formData,
      onUploadProgress,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: this.accessToken,
      },
      cancelToken: source.token,
    })
      .then((response) => {
        this.setData(response["data"]);
        this.setStatus(response.status, response.headers);
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown))
          console.log("Request canceled", thrown.message);
        else
          console.error(
            "An error has ocurred during axios call:",
            thrown.message
          );
      });
  }

  async fileUpload(url, files, data = null, onUploadProgress) {
    // debugger;
    const formData = new FormData();

    console.log(data);
    formData.append("data", data);

    console.log(files.document);
    files.document.map((file, index) =>
      formData.append("files", file, file.name)
    );
    await axios({
      baseURL: `${this.connectionObject.ip}`,
      method: "POST",
      url,
      data: formData,
      onUploadProgress,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: this.accessToken,
      },
    }).then((response) => {
      this.setData(response["data"]);
      this.setStatus(response.status, response.headers);
    });
  }
}

//base domain
// parameters: the route
//return results

// methods get or post

//errors
// timeout
// wrong route

// manage exceptions
