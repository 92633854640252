import { useDispatch } from "react-redux";
import { emptyMessages } from "../features/Chat/chatSlice";
import Connection from "./Connection";
import { logOut, setError } from "../features/modules/Header/headerSlice";
import { navigate } from "hookrouter";
const useCheckToken = () => {
  const dispatch = useDispatch();
  const checkToken = async () => {
    const t = sessionStorage.getItem("t");
    if (t) {
      const conn = new Connection(t);
      const response = await conn.create("/user/checksession/");
      if (response.status.code !== 200) {
        dispatch(emptyMessages());
        dispatch(
          logOut({
            isError: true,
            message: "Your session has expired, please login in back again",
          })
        );
        dispatch(
          setError({
            isError: true,
            message: "Your session has expired, please login in back again",
          })
        );
      } else if (
        window.location.pathname === "/" &&
        response.status.code === 200
      )
        navigate("/home");
      // else{
      // }
    }
  };
  return checkToken;
};

export default useCheckToken;
