import React from "react";
import Login from "./features/Login/Login";
import Home from "./features/Home/Home";
import Chat from "./features/Chat/Chat";
import ForgotPass from "./features/ForgotPass/ForgotPass";
import NewPass from "./features/ForgotPass/NewPass/NewPass";
import Error from "./features/Error/Error";
const routes = {
  "/": () => <Login />,
  "/home": () => <Home />,
  "/chat": () => <Chat />,
  "/forgotPassword": () => <ForgotPass />,
  "/newPass/:hash?": ({ hash }) => <NewPass hash={hash} />,
  "/error": () => <Error />,
  // '/products': () => <ProductOverview />,
  // '/products/:id': ({id}) => <ProductDetails id={id} />
};
export default routes;
