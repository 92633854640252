import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import favicon from "../../../assets/favicon.svg";
import ChatDate from "../ChatDate/ChatDate";
import parse from "html-react-parser";
const Response = ({ text, date, handleStop, isJsx, url }) => {
  const maxCharacters = 1200;
  const isLongText = text.length > maxCharacters;
  const [showAll, setShowAll] = useState(!isLongText);

  const showNewPage = () => {
    window.open(url, "_blank");
  };

  const truncateAtMarkup = (inText) => {
    let retText = inText;
    if (inText.length > maxCharacters) {
      // Try to avoid truncating inside a markup tag
      let indxMarkup = inText.indexOf('<', maxCharacters - 200);
      if (indxMarkup < 0) {
        // Truncating at maxCharacters - no markup found
        retText = retText.substring(0, maxCharacters);
      } else if (indxMarkup > maxCharacters) {
        // Truncating at maxCharacters because no markup found in last 200 chars of text before maxCharacters
        retText = retText.substring(0, maxCharacters);
      } else {
        // Truncating at begin of first markup found within last 200 chars before maxCharacters
        retText = retText.substring(0, indxMarkup);
      }
    }
    
    return retText;
  }

  return (
    <Row className="chat-element">
      <Col xs={12}>
        <Row className="flex-column">
          <Col xs="auto">
            <ChatDate date={date} />
          </Col>
          <Col>
            <Row className="flex-columnjustify-content-start">
              <Col xs="auto">
                <img className="chat-favicon" src={favicon} alt="favicon" />
              </Col>
              <Col className="chat-left-bubble-wrapper">
                <div className="chat-left-bubble" onClick={handleStop}>
                  {isJsx
                    ? text
                    : parse(
                        showAll
                          ? text
                          : truncateAtMarkup(text) ||
                              "I didn't understand. Can you repeat the question?"
                      )}
                  {!showAll && (
                    <>
                      <br />
                      <button
                        className="button-as-link"
                        onClick={(e) => setShowAll(true)}
                      >
                        See more...
                      </button>
                    </>
                  )}
                  <Col style={{ textAlign: "end" }} xs={12}>
                    {url && url !== "None" ? (
                      <Button
                        style={{
                          fontWeight: "bold",
                          background: " #e4ecef",
                          border: "none",
                          color: "black",
                        }}
                        onClick={() => showNewPage()}
                      >
                        Click here to open PI Document.
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Response;
