import React, { useState } from "react";
import { Container, Col, Row, Form } from "react-bootstrap";
import bgImg from "../../assets/bgImg.svg";
import withWatson from "../../assets/withWatson.svg";
import RenderImg from "../modules/RenderImg/RenderImg";
import "./Login.scss";
// import { navigate } from "hookrouter";
import Connection from "../../utils/Connection";
// import { navigate } from "hookrouter";
import { useDispatch } from "react-redux";
import { logIn, setUser } from "../modules/Header/headerSlice";
import JwtDecode from "jwt-decode";
import { A, useQueryParams } from "hookrouter";
// import { navigate } from "hookrouter";
const Login = ({ props }) => {
  const [queryParams] = useQueryParams();
  const { isError = false } = queryParams;
  const { message = "" } = queryParams;
  const dispatch = useDispatch();
  const [data, setData] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  const sendData = async (e) => {
    // e.preventDefault();
    const conn = new Connection();
    const response = await conn.create("/user/login/", data);
    if (response.status.code === 200) {
      dispatch(logIn(response.data.token));
      const tokenUser = JwtDecode(response.data.token).identity;
      dispatch(setUser(tokenUser));
      // sessionStorage.setItem("t", response.data.token || "");
      // navigate("/home", true);
    } else {
      setError("Incorrect Username or Password. Please try again.");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    sendData();
  };
  const handleChange = (e) => {
    setError("");

    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  return (
    <Container className="login containers" fluid>
      <Row className="flex-column login-flex-container justify-content-center align-items-center">
        <Col xs="auto">
          <Row className="justify-content-center">
            <Col xs="auto">
              <img src={bgImg} alt="background" className="logo" />
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col xs="auto">
              <h2 className="text-center login-welcome">
                Welcome to the{" "}
                <span style={{ color: "#93D500" }}> AdMed PI Assistant</span>.
              </h2>
              <h2 className="text-center login-welcome">
                Please sign in below.
              </h2>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row className="align-items-center flex-column">
              {error && <span className="login-error">{error}</span>}
              {isError && <span className="login-error">{message}</span>}
              <Col xs="10" md="11" lg="12">
                <input
                  className="login-input login-inputs"
                  required
                  type="email"
                  onChange={handleChange}
                  name="username"
                  placeholder="E-MAIL"
                />
              </Col>

              <Col xs="10" md="11" lg="12">
                <input
                  required
                  className="login-input login-inputs"
                  onChange={handleChange}
                  type="password"
                  name="password"
                  placeholder="PASSWORD"
                />
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col xs="auto">
                <A href="/forgotPassword" className="login-forgot">
                  Forgot Password
                </A>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto">
                <button type="submit" className="login-login-btn">
                  Login
                </button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {/* <div className="copyright-wrapper"> */}

      <div className="watson-logo-login">
        <RenderImg
          className="login-with-watson"
          src={withWatson}
          alt="with Watson badge"
        />
      </div>
      <Row className="align-items-center flex-column">
        <Col xs="auto">
          <p className="text-center login-trademark">
            With Watson is a trademark of International Business Machines
            Corporation, registered in many jurisdictions worldwide.
          </p>
        </Col>
        <Col className="login-copyright-footer" xs="auto">
          <p className="text-center login-trademark">
            Copyright AdMed Inc 2020
          </p>
        </Col>
      </Row>
      {/* </div> */}
    </Container>
  );
};

export default Login;
