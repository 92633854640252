import React from "react";
import { Row, Col } from "react-bootstrap";
import ChatDate from "../ChatDate/ChatDate";

const Question = ({ text, date }) => {
  return (
    <Row className="chat-element">
      <Col xs={{ offset: 3, span: 9 }}>
        <Row className="flex-column">
          <Col>
            {/* <p className="chat-date">12:12 PM Apr 1, 2020</p> */}
            <ChatDate date={date} />
          </Col>
          <Col>
            <div className="chat-right-bubble">{text}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Question;
