import * as React from "react";
const SendButton = ({ color }) => (
  <svg
    width="48"
    height="31"
    viewBox="0 0 48 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.0783 15.7207L7.77671 15.7206L0 15.7207L7.77671 30.2286L47.0783 15.7207Z"
      fill={color ? color : "#CACACA"}
    />
    <path
      d="M47.0783 15.3613L7.77671 0L0 15.3613H7.77671H47.0783Z"
      fill={color ? color : "#CACACA"}
    />
  </svg>
);
export default SendButton;
