import React from "react";
import { Row, Modal, Col } from "react-bootstrap";

const MessageModal = ({ message, setClose }) => {
  // const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <Modal show={true} onHide={setClose}>
      <Row className="justify-content-end">
        <Col xs="auto">
          <h2 className="menu-element" onClick={setClose}>
            X
          </h2>
        </Col>
      </Row>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col xs="auto">
            <h2 style={{ textAlign: "center" }}>{message}</h2>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default MessageModal;
