import React from "react";
import { Col, Row } from "react-bootstrap";
import bgImg from "../../../assets/bgImg.svg";
import downArrow from "../../../assets/down-arrow.svg";
// import whiteArrow from "../../../assets/white-arrow.svg";
// import { useSelector } from "react-redux";

// import withWatson from "../../assets/withWatson.svg";
import RenderImg from "../RenderImg/RenderImg";
import "./SidePanel.scss";
// import { navigate } from "hookrouter";
// import "./Login.scss";
const Home = ({ props }) => {
  // const width = useSelector((state) => state.header.width);

  return (
    <div className="side-panel">
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <Col xs="auto" style={{ textAlign: "center", color: "white" }}>
          {/* <span style={{ float: "right" }}>
          <img
            style={{ marginLeft: "60%" }}
            src={whiteArrow}
            alt="whitearrow"
          />
        </span> */}
          <RenderImg
            src={bgImg}
            width="210"
            height="203"
            alt="background Image"
          />
          <div className="side-panel-text">
            What would <br /> you like to <br /> know?
            <br />
            <img src={downArrow} alt="down arrow" />
          </div>
          <br />
        </Col>
      </Row>
      <div className="enter-question-text">
        <div
          style={{ background: "#29354c", color: "white" }}
          className="panel-footer"
        >
          <span className="panel-span">Enter your question below.</span>
        </div>
      </div>
    </div>
  );
};

export default Home;
