import React from "react";
import "./Circle.scss";
const Circle = ({ color, block, className }) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className={`user-circle ${className ? className : ""}`}
    ></div>
  );
};

export default Circle;
