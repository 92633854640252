import React, { useState } from "react";
import { Row, Container, Col, Form } from "react-bootstrap";
import bgImg from "../../../assets/bgImg.svg";
import withWatson from "../../../assets/withWatson.svg";

import { A } from "hookrouter";
import Connection from "../../../utils/Connection";
import MessageModal from "./MessageModal/MessageModal";
const NewPass = ({ hash }) => {
  const [modal, setModal] = useState({ message: "", show: false });
  const [data, setData] = useState({ password: "", repeatPassword: "" });
  const [error, setError] = useState({ isError: false, message: "" });
  const sendData = async (e) => {
    // e.preventDefault();
    console.log({
      new_password: data.password,
      hash,
    });

    const conn = new Connection();

    const response = await conn.create("/user/changePassword/", {
      new_password: data.password,
      hash,
    });
    console.log(response);
    if (response.status.code === 200) {
      // sessionStorage.setItem("t", response.data.token || "");
      // navigate("/home", true);
      setModal({
        message: (
          <>
            Password changed successfully. <br />
            Go to the <A href="/">main page</A>.
          </>
        ),
        show: true,
      });
      //   navigate("/");
    } else {
      setModal({
        message: response.status.message.error,
        show: true,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!error.isError) sendData();
  };
  const handleChange = (e) => {
    try {
      e.target.setCustomValidity("");
    } catch (e) {}
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSame = (e) => {
    const { password, repeatPassword } = data;
    const arePasswordSame = password === repeatPassword;
    setError({
      isError: arePasswordSame ? false : true,
      message: arePasswordSame ? "" : "Passwords do not match.",
    });
  };
  return (
    <>
      {modal.show && (
        <MessageModal
          message={modal.message}
          setClose={() => setModal({ ...modal, show: false })}
        />
      )}
      <Container className="login containers" fluid>
        <Row className="flex-column login-flex-container justify-content-center align-items-center">
          <Col xs="auto">
            <Row className="justify-content-center">
              <Col xs="auto">
                <img src={bgImg} alt="background" className="logo" />
              </Col>
            </Row>

            <Row className="justify-content-center align-items-center">
              <Col xs="auto">
                <h2 className="text-center login-welcome">
                  Welcome to the{" "}
                  <span style={{ color: "#93D500" }}> AdMed PI Assistant</span>.
                </h2>
                <h2 className="text-center login-welcome">
                  Please enter new password below.
                </h2>
              </Col>
            </Row>
            {/* <Row className="justify-content-center align-items-center">
            <Col xs="auto">
              <small>
                Minimun 6 characters string with at least one digit and one
                upper case letter, one special symbol.
              </small>
            </Col>
          </Row> */}

            <Form onSubmit={handleSubmit}>
              <Row className="align-items-center flex-column">
                <Col xs="10" md="11" lg="12">
                  <input
                    required
                    className="login-input login-inputs"
                    onChange={handleChange}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Please, enter: minimun 6 characters string with at least one digit and one upper case letter, one special symbol (“!@#$%^&*()\\-_=+{};:,<.>”)"
                      )
                    }
                    // onChange="try{setCustomValidity('')}catch(e){}" />
                    // minimun 6 characters string with at least one digit and one upper case letter, one special symbol (“!@#$%^&*()\-_=+{};:,<.>”).
                    pattern="^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+{};:,<.>])\S{6,}"
                    //   pattern="^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*"
                    type="password"
                    name="password"
                    placeholder="NEW PASSWORD"
                  />
                </Col>
              </Row>

              <Row className="align-items-center flex-column">
                <Col xs="10" md="11" lg="12">
                  <input
                    required
                    className="login-input login-inputs"
                    onChange={handleChange}
                    onFocus={() => setError({ isError: false, message: "" })}
                    type="password"
                    name="repeatPassword"
                    onBlur={handleSame}
                    placeholder="CONFIRM NEW PASSWORD"
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs="auto">
                  {error.isError ? (
                    <small className="text-danger">{error.message}</small>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="auto">
                  <button type="submit" className="login-login-btn">
                    Submit
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {/* <div className="copyright-wrapper"> */}

        <Row className="justify-content-center">
          <Col xs="auto">
            <img
              className="login-with-watson"
              src={withWatson}
              alt="with Watson badge"
            />
          </Col>
        </Row>
        <Row className="align-items-center flex-column">
          <Col xs="auto">
            <p className="text-center login-trademark">
              With Watson is a trademark of International Business Machines
              Corporation, registered in many jurisdictions worldwide.
            </p>
          </Col>
          <Col xs="auto">
            <p
              style={{ marginBottom: 0 }}
              className="text-center login-trademark"
            >
              Copyright AdMed Inc 2020
            </p>
          </Col>
        </Row>
        {/* </div> */}
      </Container>
    </>
  );
};

export default NewPass;
