import React from "react";
import "./Menu.scss";
import { Row, Col, Modal } from "react-bootstrap";
import Connection from "../../../../utils/Connection";
import { useDispatch } from "react-redux";
import { logOut } from "../headerSlice";
import { emptyMessages } from "../../../Chat/chatSlice";
// import { navigate } from "hookrouter";
const Menu = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  // const handleShow = () => setShow(true);
  const t = sessionStorage.getItem("t");
  const handleLogout = async (e) => {
    e.preventDefault();
    const conn = new Connection(t);
    await conn.create("/user/logout/");
    //const response = await conn.create("/user/logout/");
    //if (response.status.code === 200) {
      dispatch(logOut());
      dispatch(emptyMessages());
      handleClose();
    //}
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="menu">
            <Row className="justify-content-end">
              <Col xs="auto">
                <h2 className="menu-element" onClick={handleClose}>
                  X
                </h2>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center flex-column">
              <Col xs="auto">
                <a className="menu-element menu-element-hover transparent-links" href="about.html" target="_blank" rel="noopener noreferrer">ABOUT</a>
              </Col>
              <Col xs="auto">
                {t && (
                  <h2
                    onClick={handleLogout}
                    className="menu-element menu-element-hover"
                  >
                    LOG OUT
                  </h2>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
  // return (
  //   <div className="menu">
  //     {/* <Container> */}
  //     <Row className="justify-content-center align-items-center flex-column">
  //       <Col xs="auto">
  //         <h2 className="menu-element">ABOUT</h2>
  //       </Col>
  //       <Col xs="auto">
  //         <h2 className="menu-element">HELP</h2>
  //       </Col>
  //       <Col xs="auto">
  //         <h2 className="menu-element">FAQ</h2>
  //       </Col>
  //     </Row>
  //     {/* </Container> */}
  //   </div>
  // );
};

export default Menu;
