import { navigate } from "hookrouter";
import React from "react";
import { Row, Container, Col, Form } from "react-bootstrap";
import {useDispatch} from "react-redux";
import bgImg from "../../assets/bgImg.svg";
import withWatson from "../../assets/withWatson.svg";
import { emptyMessages } from "../Chat/chatSlice";
const Error = () => {
  const dispatch = useDispatch();
  const sendData = async (e) => {
    // e.preventDefault();
    console.log("sendData about to navigate to /home");
    navigate("/home", true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(emptyMessages());
    sendData();
  };

  return (
    <Container className="error containers" fluid>
      {/* <Row
        className="justify-content-center align-items-center"
        style={{ position: "absolute", marginLeft: "0 auto", left: "50%" }}
      >
        <Col xs="auto">
          <Alert variant="success">
            A password reset link was sent to {email}
          </Alert>
        </Col>
      </Row> */}
      <Row className="flex-column login-flex-container justify-content-center align-items-center">
        <Col xs="auto">
          <Row className="justify-content-center">
            <Col xs="auto">
              <img src={bgImg} alt="background" className="logo" />
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center">
            <Col xs="auto">
              <h2 className="text-center error-welcome">
                Welcome to the{" "}
                <span style={{ color: "#93D500" }}> AdMed PI Assistant</span>.
              </h2>
              <h2 className="text-center error-welcome">
                {(
                  <>
                    The system is currently not available
                  </>
                )}
              </h2>
            </Col>
          </Row>
          {(
            <Form onSubmit={handleSubmit}>
              <Row className="justify-content-center">
                <Col xs="auto">
                  <button type="submit" className="login-login-btn">
                    Recheck
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
      </Row>
      {/* <div className="copyright-wrapper"> */}
      <Row className="justify-content-center">
        <Col className="error-watson-container" xs="auto">
          <img
            className="error-with-watson"
            src={withWatson}
            alt="with Watson badge"
          />
        </Col>
      </Row>
      <Row className="align-items-center flex-column">
        <Col xs="auto">
          <p className="text-center login-trademark">
            With Watson is a trademark of International Business Machines
            Corporation, registered in many jurisdictions worldwide.
          </p>
        </Col>
      </Row>
      {/* </div> */}
      <Col
        style={{
          bottom: "0",
          paddingBottom: "0",
          width: "100%",
        }}
        xs="auto"
      >
        <p className="text-center login-trademark">Copyright AdMed Inc 2020</p>
      </Col>
    </Container>
  );
};

export default Error;
